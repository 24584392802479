import { trackPromise } from 'react-promise-tracker';
export const fetchData = async (queryPost) =>{
    
    const response = await trackPromise(fetch('https://birobit.com/graphql', 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: queryPost
      })
    }))
    if(!response.ok){
        throw new Error('Something went wrong')
    }
    const json = await response.json();
    return json;
  }