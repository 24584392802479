import React from "react";
import ImageTextLeftSide from "./ImageTextLeftSide";
import ImageTextRightSide from "./ImageTextRightSide";

const ImageWithText = ({image, title, content, srcimg}) =>{
    if(image === "leftSide"){
        return(
          <ImageTextLeftSide title={title} content={content} srcimg={srcimg}/>
        )
    }
    if(image==="rightSide"){
      return(
        <ImageTextRightSide title={title} content={content} srcimg={srcimg}/>
        )
    }
    
}

export default ImageWithText;