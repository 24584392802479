import {useSelector} from 'react-redux';
const Team = () => {
  const allContent = useSelector((state)=> state.content.allContent);
  if(allContent){
    const content = allContent.data.teamMembers.nodes;
    return(
<div class="container my-5 py-5 shadow-5">

  <section class="text-center">

    <h3 class="mb-5">Our Team</h3>

    <div class="row">
      {content.map((member)=> {
        let email = member.ourTeamCustomFields.memberEmail;
        return(

          <div class="col-md-4 mb-md-0 mb-4">
            <img class="rounded shadow-1-strong mb-4" src={member.featuredImage? member.featuredImage.node.sourceUrl: ''}
              alt="avatar" style={{width: '150px'}} />
            <h5 class="mb-3">{member.title}</h5>
            <div dangerouslySetInnerHTML={{__html: member.content}}></div>
            <ul class="list-unstyled mb-0">
            <a href={`mailto:${email}`} class="px-1">
                <i class="fas fa-envelope"></i>
              </a>
            </ul>
        </div>
        )
      })}
      
    </div>

  </section>

</div>
    )
  }
}

export default Team;