import React from "react";

const ImageTextRightSide = ({title, content, srcimg}) =>{
    return(
<div class="container my-5 py-5">

  
  <section class="">
    

    <div class="row gy-0 every-second-service">
      

      <div class="col-lg-7 mb-4 mb-md-0">
        <div class="h-100 p-4 ps-lg-5 d-flex align-items-center shadow-5" style={{background: '#fff', color: '#333'}}>
          <div class="ps-lg-5">
            <h2 class="fw-bold mb-4">{title}</h2>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
        </div>
      </div>
      <div class="col-lg-5 mb-4 mb-md-0">
        <div class="d-flex py-md-5">
          <img
               src={srcimg}
               class="w-100"
               id="cta-img-nml-50"
               style={{zindex: '10'}}
               alt=""
               />
        </div>
      </div>
    </div>
  </section>
</div>
    )
}

export default ImageTextRightSide;