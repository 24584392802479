import {useSelector} from 'react-redux';

const Specialities = () => {
    const allContent = useSelector((state)=>state.content.allContent)
    if(allContent){
      const content = allContent.data.ourSpecialities.nodes;
    return(
      <>
      <h2 class="mb-3 h1 fw-bold" style={{textAlign: 'center', marginTop: '70px'}}>{content[0].contentType.node.label}</h2>
        <div class="container my-5 py-5 shadow-5">

  <section className='specialities-checklist'>

    <div class="row my-5">
      {
        content.map((item)=> {
          return(
            
            <div class="col-md-4 mb-4 mb-md-0">
            <div class="row">
              <div class="col-1 col-md-2 text-center">
                <i class="fas fa-solid fa-check fa-2x text-primary"></i>
              </div>
              <div class="col-10 col-md-9 col-lg-10 float-end">
                <h5>{item.title}</h5>
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
              </div>
            </div>
          </div>
          )
        })
      }
    </div>

  </section>

</div>
</>
    )
    }
}

export default Specialities;