import {useSelector} from 'react-redux';

const FullRating = () =>{
  return(
    <li><i class="fas fa-star fa-sm"></i></li>
  )
}
const HalfRating = () =>{
  return(
    <li><i class="fas fa-star-half-alt fa-sm"></i></li>
  )
}
const ratings = (rate) => {
  if(!rate){
    rate=5;
  }
  let myRating = [];
  let totalStars = rate;
  let fullStars = Math.floor(rate);
  for(let s=0; s<fullStars; s++){
    myRating.push({rate: 'full'});
  }
  if(totalStars-fullStars===0.5){
    myRating.push({ rate:'half'});
  }
  return myRating;
}

const Testimonials = ()=> {
  const allContent = useSelector((state)=> state.content.allContent);
  if(allContent){
    const content = allContent.data.ourTestimonials.nodes;
    return(
<div class="container my-5 py-5 shadow-6">

  
  <section>

   

    <h3 class="text-center mb-5">Testimonials</h3>

    
    <div id="testimonialsCarousel" class="carousel slide" data-mdb-ride="carousel">
     
      <ol class="carousel-indicators">
        {content.map((item, index)=>{return(
          <li data-mdb-target="#testimonialsCarousel" data-mdb-slide-to={index} class={index===0? 'active': ''}></li>
        )})}
      </ol>

      <div class="carousel-inner text-center">
        {content.map((item, index)=> { return(
          <div class={`carousel-item ${index===0? 'active': ''}`}>
          <div class="row">
            <div class="col-md-12 col-lg-8 col-xl-8 mb-4 mx-auto">
              <img class="rounded-circle shadow-1-strong mb-4"
                src={item.featuredImage? item.featuredImage.node.sourceUrl : ''} alt="avatar"
                style={{width: '150px'}} />
              <div>
                <i class="fas fa-quote-left pe-2"></i>
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
              </div>
              <h5 class="mb-3">{item.title}</h5>
              <ul class="list-unstyled d-flex justify-content-center text-primary mb-0">
                {ratings(item.testimonials.reviewStars).map((i)=>{
                  return(
                    <>
                    {i.rate==='full'? <FullRating/> : <HalfRating/>}
                    </>
                  )
                }) }
              </ul>
            </div>
          </div>
        </div>

        )})}
        
       
      </div>
      <a class="carousel-control-prev text-black" href="#testimonialsCarousel" role="button" data-mdb-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next text-black" href="#testimonialsCarousel" role="button" data-mdb-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

  </section>

</div>
    )
}
}

export default Testimonials;