import { useSelector } from "react-redux"

const Footer = () =>{
  const allContent = useSelector((state)=> state.content.allContent)
  if(allContent){
    return(
        <section class="">
  <footer class="text-center text-white" style={{'background': '#777'}}>
    <div class="text-center p-3" style={{'background': '#000'}}>
      © {new Date().getFullYear()} Copyright: 
      <a class="text-white" href="https://destinationlawyers.com.au/"> Destination Lawyers</a>
    </div>
    
  </footer>
</section>
    )
  }
}

export default Footer;