import {useSelector} from 'react-redux';

const Banner = ({data})=> {
    
    const allContent = useSelector((state)=> state.content.allContent);
    if(allContent != ''){
    const content = allContent.data.heroBannerSettings.nodes[0];
    const contactStuff = allContent.data.contactUsSettings.nodes[0];
    const backg = content.featuredImage? content.featuredImage.node.sourceUrl: '';
    return(
        <section id="banner" className="shadow-5">
  <div id="intro" class="bg-image"
      style={{backgroundImage: `url(${backg})`, height: '85vh'}}>
      <div class="mask">
        <div class="container d-flex justify-content-center align-items-center h-100">
          <div class="row align-items-center text-white">
            <div class="col-lg-6 my-5">
              <h1>{content.title}</h1>
              <hr class="my-4" style={{opacity: 1}} />
              <div class="lead mb-5" style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: content.content}}>

              </div>
              <a href="#contact" class="btn btn-primary btn-lg me-2 mb-2 mb-md-0">{content.homePageBanner.button1}</a>
              <a href={`tel:${contactStuff.contactUsCustomFields.contact1}`} class="btn btn-outline-light btn-lg mb-2 mb-md-0">{content.homePageBanner.button2}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </section>
    )
    }
}

export default Banner;