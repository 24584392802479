let bannerQuery = `
    heroBannerSettings {
        nodes {
          content
          title
          homePageBanner {
            button1
            button1Url {
              url
            }
            button2
            button2Url {
              url
            }
          }
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
  `;
  let aboutQuery = `
    aboutUsContent {
        nodes {
          title
          content
          customFieldsAboutUs {
            aboutUsSubHeader
          }
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
    }
  `;
  let specialitiesQuery = `
    ourSpecialities {
      nodes {
        title
        content
        contentType {
          node {
            label
          }
        }
      }
    }
  `;
  let servicesQuery = `
    services {
      nodes {
        title
        content
        status
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
      }
    }
  `;
  let teamQuery = `
    teamMembers {
      nodes {
        title
        content
        status
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        ourTeamCustomFields {
            memberEmail
        }
      }
    }
  `;
  let testimonialsQuery = `
    ourTestimonials {
        nodes {
          title
          content
          testimonials {
            reviewStars
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
    }
  `;
  let contactQuery = `
    contactUsSettings {
        nodes {
          title
          contactUsCustomFields {
            address
            contact1
            contact2
            email1
            email2
          }
        }
    }
  `;

  //new queries
  const queryHead = 'query getViewer {';
  const queryFooter = '}';

  let queryEverything = '';
  queryEverything = queryEverything.concat(queryHead, bannerQuery, aboutQuery, specialitiesQuery, servicesQuery, teamQuery, testimonialsQuery,contactQuery, queryFooter);
  export {queryEverything};