import { configureStore } from "@reduxjs/toolkit";
import contentReducer  from "./dataSlice";

export const store = configureStore({
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    reducer: {
     content: contentReducer
    }
})