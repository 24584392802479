import React from "react"
import logo from "../assets/imgs/transparent-logo.png"
import {useSelector} from 'react-redux';

const NavbarMDB = () =>{
  const allContent = useSelector((state)=> state.content.allContent);
  if(allContent != ''){
    
    const contactStuff = allContent.data.contactUsSettings.nodes[0];
      return(
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <a class="navbar-brand me-2" href="https://destlawyers.com.au/">
            <img
              src={logo}
              height="100"
              alt="MDB Logo"
              loading="lazy"
              style={{marginTop: '-1px'}}
            />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarButtonsExample"
            aria-controls="navbarButtonsExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarButtonsExample">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="https://destlawyers.com.au">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#services">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">Contact Us</a>
              </li>
            </ul>

            <div class="d-flex align-items-center">
              <a class="btn btn-primary me-3" style={{background: '#9929A2'}} href="#contact">
                Enquire
              </a>
              <a
                class="btn btn-dark px-3"
                href={`tel:${contactStuff.contactUsCustomFields.contact1}`}
                role="button"
                style={{background: '#c2cbd2', color: '#9929A2'}}
                ><i class="fa fa-phone" style={{color: '#9929A2'}}></i
              > Call</a>
            </div>
          </div>
          
        </div>
        
      </nav>
      )
}
}

export default NavbarMDB;