import {useSelector} from 'react-redux';
import { fetchContact } from '../states/fetchContact';
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";



const Contact = ()=> {
const inititalValues = {
  fullname: '',
  email : '',
  message: ''
}  
const [values, setValues] = useState(inititalValues);
const [err, setErr] = useState([]);
const [sent, setSent] = useState('');
const [captchaToken, setCaptchaToken] = useState(null);
const captchaRef = useRef(null);

const handleInput = (e) => {
  const {name, value} = e.target;
  setValues({...values, [name]: value})
}

const onChange = () =>{
  captchaRef.current.getResponse().then(res => {
      setCaptchaToken(res)
  })

}
const handleSubmit = (e) =>{
    e.preventDefault();
    const token = captchaRef.current.getValue();
    const response = fetchContact(values, token).then(result => 
      {
        setSent(result.success)
        setErr(result.errors)
      }
    );

    
    captchaRef.current.reset();
    
    
    return false;
    
    
  
}


const allContent = useSelector((state)=> state.content.allContent);
  if(allContent){
    const content = allContent.data.contactUsSettings.nodes[0];
    return(
<div id="contact" class="container my-5 py-3">

  <section class="mb-10">
    <h2 class="fw-bold mb-5 text-center">{content.title}</h2>

    <div class="row gx-lg-5">
      <div class="col-lg-5 mb-4 mb-lg-0">
        <form onSubmit={handleSubmit}>
          <div class="form-outline mb-4">
            <input maxlength="50" type="text" required name="fullname" id="form4Example1" onChange={handleInput} class="form-control" style={{backgroundColor: '#eee'}} />
            <label class="form-label" for="form4Example1">Name*</label>
            <span style={{textColor: 'grey', fontSize: '10px', fontStyle: 'italic'}}>{values['fullname']? values['fullname'].length: 0} chars used max 50 allowed</span>
          </div>

          <div class="form-outline mb-4">
            <input maxlength="50" onChange={handleInput} required type="email" name="email" id="form4Example2" class="form-control" style={{backgroundColor: '#eee'}} />
            <label class="form-label" for="form4Example2"
                   >Email address*</label
              >
              <span style={{textColor: 'grey', fontSize: '10px', fontStyle: 'italic'}}>{values['email']? values['email'].length: 0} chars used max 50 allowed</span>
          </div>

          <div class="form-outline mb-4">
            <textarea required name="message" maxLength="100"
                      class="form-control"
                      id="form4Example3"
                      rows="4"
                      style={{backgroundColor: '#eee'}} onChange={handleInput}></textarea>
            <label class="form-label" for="form4Example3">Message*</label>
            <span style={{textColor: 'grey', fontSize: '10px', fontStyle: 'italic'}}>{values['message']? values['message'].length: 0} chars used max 100 allowed</span>
          </div>

          <div className='form-outline mb-4'>
          <ReCAPTCHA
              sitekey="6LcOQmQUAAAAACqmJnu3GdQMubfbTGeDH6AV6Nlz"
              ref={captchaRef}
              onChange={onChange} 
              onVerify={onChange} 
            />
          </div>

          

          <button type="submit" class="btn btn-primary btn-block mb-4">
            Send
          </button>
        </form>
        
          {err? err.map(item=> { return(
            <div class="alert alert-danger" role="alert">
              
              <p>{item}</p> 
           
            </div>
          )}): ''}
          {sent? <div class="alert alert-success" role="alert">{sent}</div> : '' }
        
      </div>

      <div class="col-lg-7 mb-4 mb-md-0">
        <div class="row gx-lg-5">
          <div class="col-md-6 mb-5">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 badge-primary rounded-4">
                  <i class="fas fa-home fa-lg fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Our Address</p>
                <p class="text-muted mb-0">{content.contactUsCustomFields.address}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-5">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 badge-primary rounded-4">
                  <i class="fas fa-envelope fa-lg fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Email Address</p>
                <p class="text-muted mb-0">{content.contactUsCustomFields.email1}</p>
                <p class="text-muted mb-0">{content.contactUsCustomFields.email2}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-5">
            <div class="d-flex align-items-start">
              <div class="flex-shrink-0">
                <div class="p-3 badge-primary rounded-4">
                  <i class="fa fa-phone fa-lg fa-fw"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-4">
                <p class="fw-bold mb-1">Call Us</p>
                <p><a href={`tel:${content.contactUsCustomFields.contact1}`} class="text-under text-muted mb-0">{content.contactUsCustomFields.contact1}</a></p>
                <p><a href={`tel:{content.contactUsCustomFields.contact2}`} class="text-under text-muted mb-0">{content.contactUsCustomFields.contact2}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
    )
}
}

export default Contact;