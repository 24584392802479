import {useSelector} from 'react-redux';

const About = () => {
  const allContent = useSelector((state)=> state.content.allContent);
  if(allContent){
    const content = allContent.data.aboutUsContent.nodes[0];
    const myImage = content.featuredImage? content.featuredImage.node.sourceUrl: '';
    return(
      <>
      <div id="about">&nbsp;</div>
      <h2 class="mb-3 h1 fw-bold" style={{textAlign: 'center', marginTop: '70px'}}>{content.title}</h2>
<div class="container my-5 p-5 shadow-5">

  
  <section>

    <div class="row">
      <div class="col-lg-6 col-xl-5 mb-4 mb-lg-0 pb-2 pb-lg-0 pb-xl-2">
        <img src={myImage} className="img-fluid shadow-2-strong rounded" alt="About Us Image" />
      </div>
      <div class="col-lg-6 col-xl-6 offset-xl-1">
        <h4 class="mb-4">{content.customFieldsAboutUs.aboutUsSubHeader}</h4>
        <div dangerouslySetInnerHTML={{__html: content.content}}></div>
      </div>
    </div>

  </section>

</div>
</>
    )
  }
}

export default About;