import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from '../src/states/store';
import { usePromiseTracker } from "react-promise-tracker";
import {ThreeDots} from 'react-loader-spinner';

const root = ReactDOM.createRoot(document.getElementById('root'));
const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && 
    <div
      style={{
        width: "100%",
        height: "100VH",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ThreeDots type="ThreeDots" color="#9929A2" height="100" width="100" />
    </div>
   );  
}
root.render(
  <Provider store={store}>
    <App />
    <LoadingIndicator />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
