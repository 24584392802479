import React from 'react';
import './App.scss';
import NavbarMDB from './components/Navbar';
import ImageWithText from './components/ImageWithText';
import Testimonials from './components/Testimonials';
import About from './components/AboutUs';
import Footer from './components/Footer';
import Specialities from './components/Specialities';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';
import Banner from './components/Banner';
import { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { allContent } from './states/dataSlice';
import { fetchData } from './states/fetchData';
import { queryEverything } from './states/queries';

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const loadAllContent = () => {
    new Promise((resolve, reject) => {
      try{
        fetchData(queryEverything).then(result => dispatch(allContent(result)))
        resolve();
      }catch(err){
        reject();
        throw new Error('Something is wrong', err)
      }
      
    }).then(()=> console.log('data loading')).finally(()=>setLoading(true));
  }
  useEffect(()=>{
    loadAllContent();
    
  },[])
  if(!loading){
    return(
      <p>Loading</p>
    )
  }else{
  return (
    <>
    <NavbarMDB></NavbarMDB>
    <Banner />
    <About />
    <Specialities/>
    <Services />
    <Team />
    <Testimonials  />
    <Contact />
    <Footer />
    </>
  );
}
}

export default App;
