import { trackPromise } from 'react-promise-tracker';
export const fetchContact = async (data, token) =>{

    const response = await trackPromise(fetch('https://birobit.com/form/index.php', 
    {
      method: 'POST',
      body: JSON.stringify({
        data, token
      })
    }))
    if(!response.ok){
        throw new Error('Something went wrong')
    }
    const json = await response.json();
    return json;
  }