import { useSelector } from "react-redux"
import '../Services.scss'

import ImageWithText from './ImageWithText';

const Services = () => {
    const allContent = useSelector((state)=> state.content.allContent)
    if(allContent){
        const data = allContent.data.services.nodes;
        if(data) {
            return(
                
                <>
                <div id="services">&nbsp;</div>
                <h2 class="mb-3 h1 fw-bold" style={{textAlign: 'center', marginTop: '70px'}}>Our Services</h2>
                {
                data.map((p,i)=> {
                    return(
                        <ImageWithText key={i} image={i%2 ===0 ? 'leftSide' : 'rightSide'} title={p.title} content={p.content} srcimg={p.featuredImage.node.sourceUrl}/>
                    )
                })
                }
               </>
            )
        }
        
    }
}

export default Services;
