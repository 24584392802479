import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allContent: '',
    banner: '',
    about: '',
    specialities: '',
    services: '',
    team: '',
    testimonials: '',
    contact: ''

}

export const websiteSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        allContent: (state, action) => {
            state.allContent = action.payload;
        }
    }
})

export const { allContent } = websiteSlice.actions;

export default websiteSlice.reducer;